document.addEventListener("DOMContentLoaded", function () {
    var radio = document.querySelectorAll(".custom-radio input");

    if (radio) {
        for (var i = 0; i < radio.length; i++) {
            radio[i].addEventListener("click", function () {
                var selected =
                    this.parentElement.parentElement.querySelectorAll(
                        ".custom-radio.selected",
                    );

                if (selected) {
                    for (var j = 0; j < selected.length; j++) {
                        selected[j].classList.remove("selected");
                    }
                }
                if (this.checked) {
                    this.parentElement.classList.add('selected');

                    var other_answer_text_div = this.parentElement.parentElement.querySelector('[id*="other_answer_text_div"]');

                    if (other_answer_text_div) {
                        if (this.id.includes("other_answer")) {
                            other_answer_text_div.classList.remove('d-none');
                        } else {
                            other_answer_text_div.querySelector('[id*="other_answer_text"]').value = "";
                            other_answer_text_div.classList.add('d-none');
                        }
                    }
                }
            });
        }
    }

    var modal = document.getElementById("modal");

    if (modal) {
        var close = document.querySelector("#modal .modal-close");

        document.querySelector("html").style.overflow = "hidden";

        if (close) {
            close.addEventListener("click", function () {
                modal.classList.add("hide");
                setTimeout(function () {
                    modal.remove();
                    document.querySelector("html").style.overflow = "auto";
                }, 500);
            });
        }
    }

    const question1 = document.querySelectorAll("input[name='answer[1]']");
    const question2 = document.querySelector("#opinion-q2");
    const question3 = document.querySelectorAll("input[name='answer[3]']");
    const question4 = document.querySelector("#opinion-q4");
    const question5 = document.querySelectorAll("input[name='answer[5]']");
    const question6 = document.querySelector("#opinion-q6");
    const question7 = document.querySelectorAll("input[name='answer[7]']");
    const question8 = document.querySelector("#opinion-q8");
    const question9 = document.querySelectorAll("input[name='answer[9]']");
    const question10 = document.querySelector("#opinion-q10");

    function showHide(value, element) {
        if (value === "1") {
            element.classList.remove("d-none");
        }

        if (value === "2") {
            element.classList.add("d-none");
        }
    }

    question1.forEach((item) => {
        if (item.checked) {
            showHide(item.value, question2);
        }

        item.addEventListener("change", function () {
            showHide(item.value, question2);
        });
    });
    question3.forEach((item) => {
        if (item.checked) {
            showHide(item.value, question4);
        }

        item.addEventListener("change", function () {
            showHide(item.value, question4);
        });
    });
    question5.forEach((item) => {
        if (item.checked) {
            showHide(item.value, question6);
        }

        item.addEventListener("change", function () {
            showHide(item.value, question6);
        });
    });
    question7.forEach((item) => {
        if (item.checked) {
            showHide(item.value, question8);
        }

        item.addEventListener("change", function () {
            showHide(item.value, question8);
        });
    });
    question9.forEach((item) => {
        if (item.checked) {
            showHide(item.value, question10);
        }

        item.addEventListener("change", function () {
            showHide(item.value, question10);
        });
    });
});
